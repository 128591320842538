import * as React from "react";



export default class Contact extends React.Component {
    render() {
        return <>
            <section className="section section-md section-form-creative">
                <div className="container-1">
                    <div className="row row-fix justify-content-center">
                        <div className="col-xl-10">
                            <div className="form-creative-wrap">
                                <div className="text-center pb-5">
                                <h2 className="breadcrumbs-custom-title with-divider-blue pb-3">Kontakt</h2>
                                <p>Tekkis küsimusi seoses meie teenustega? Võta ühendust ning uuri lähemalt!</p>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-11 col-sm-10 col-10 mx-auto">
                                <div className="row row-10 justify-content-center">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-10 pb-4 pb-lg-0 text-center">
                                        <ul className="contact-list">
                                            <li><span className="icon fa fa-phone"/><a href="tel:+3727477000">+372 7477 000 (Tartu)</a></li>
                                            <li className="divider"/>
                                        </ul>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-10 pb-4 pb-lg-0 text-center">
                                        <ul className="contact-list">
                                            <li><span className="icon fa fa-phone"/><a href="tel:+3724366326">+372 4366 326 (Mustla)</a></li>
                                            <li className="divider"/>
                                        </ul>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-10 pb-4 pb-lg-0 text-center">
                                        <ul className="contact-list">
                                            <li><span className="icon fa fa-phone"/><a href="tel:+3727977531">+372 7977 531 (Kanepi)</a></li>
                                            <li className="divider"/>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                                <div className="row pt-5 justify-content-center">
                                    <div className="col-10">
                                        <ul className="contact-list">
                                            <li><span className="icon fa fa-envelope-o"/><a
                                                href="mailto:info@blendent.ee">info@blendent.ee</a></li>
                                            <li><span className="icon fa fa-clock-o"/>E - R: 8:00 kuni 17:00</li>
                                            <li><span className="icon fa fa-map-marker"/><a href="#">Juriidiline aadress: Tartumaa, Tartu linn, Ropka tee 6, 50113</a></li>
                                            <li><span className="icon fa fa-address-book"/><a href="#">Blendent OÜ, reg. kood: 10737026</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>;
    }
}