import * as React from "react";



export default class ServiceFirst extends React.Component {
    render() {
        return <>
            <section className="section section-lg bg-default">
                <div className="container">
                    <div className="row row-30 justify-content-center">
                        <div className="col-xl-10">
                            <div className="single-service">
                                <div className="row row-xl-120">
                                    <div className="col-12">
                                        <h3 className="with-divider-blue text-center">Teenuse nimi</h3>
                                        <p className="wow fadeInRight">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        <p className="wow fadeInLeft">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        <strong><p className="pt-3 wow fadeInUp">Teenuse hinnakirja leiad <a href="/hinnakiri">siit.</a></p></strong>
                                        <div className="list-group">
                                            <ul className="list-marked primary font-weight-regular">
                                                <li className="wow fadeInUp" data-wow-delay=".3s">Nõuanne või näide probleemidest/lahendustest
                                                </li>
                                                <li className="wow fadeInUp" data-wow-delay=".4s">Nõuanne või näide probleemidest/lahendustest
                                                </li>
                                                <li className="wow fadeInUp" data-wow-delay=".5s">Nõuanne või näide probleemidest/lahendustest
                                                </li>
                                            </ul>
                                            <ul className="list-marked primary font-weight-regular">
                                                <li className="wow fadeInUp" data-wow-delay=".6s">Nõuanne või näide probleemidest/lahendustest
                                                </li>
                                                <li className="wow fadeInUp" data-wow-delay=".7s">Nõuanne või näide probleemidest/lahendustest
                                                </li>
                                                <li className="wow fadeInUp" data-wow-delay=".8s">Nõuanne või näide probleemidest/lahendustest
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="single-service__media wow fadeInRight text-center pt-5"><img src="https://via.placeholder.com/1000x300"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h3 className="text-center text-md-left">Teenuse kohta mingid pildid</h3>
                                        <div className="row row-20 justify-content-center">
                                            <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay=".1s">
                                                <div className="single-service__post"><a className="media" href="#"><img src="https://via.placeholder.com/300x200"/></a>
                                                    <h4><a href="#">Lorem ipsum</a></h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay=".2s">
                                                <div className="single-service__post"><a className="media" href="#"><img src="https://via.placeholder.com/300x200"/></a>
                                                    <h4><a href="#">Lorem ipsum</a></h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay=".3s">
                                                <div className="single-service__post"><a className="media" href="#"><img src="https://via.placeholder.com/300x200"/></a>
                                                    <h4><a href="#">Lorem ipsum</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>;
    }
}