import * as React from "react";
import DentistImg from "../assets/images/dentist.png";



export default class Kanepi extends React.Component {
    render() {
        return <>
            <section className="breadcrumbs-custom bg-image">
                <div className="container">
                    <h2 className="breadcrumbs-custom-title with-divider-blue">Kanepi kliinik</h2>
                </div>
            </section>
            <section className="section section-lg bg-default">
                <div className="container text-center text-md-left">
                    <div
                        className="row justify-content-lg-between align-items-center row-30  flex-md-row">
                        <div className="col-lg-5 col-md-6">
                            <h2 className="wow fadeInLeft" data-wow-delay=".1s">Info</h2>
                            <p className="wow fadeInLeft" data-wow-delay=".3s">
                                <strong>Telefon: </strong><a href="tel:7977531">+372 7977 531</a>
                            </p>
                            <p className="wow fadeInLeft" data-wow-delay=".3s"><strong>Aadress: </strong>Turu 1, Kanepi, Põlvamaa</p>
                            <p className="wow fadeInLeft" data-wow-delay=".3s"><strong>Oleme avatud: </strong>E - R kell 8:00 - 18:00</p>
                            <a
                                className="wow fadeInLeft button button-primary button-shadow button-md" href="tel:7977531"
                                data-wow-delay=".4s">Helista</a>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="block-xs wow fadeInRight pt-5" data-wow-delay=".2s">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2115.0393239514256!2d26.75679041603334!3d57.987167924938646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eb1c45046e61fb%3A0xcd63fb11a04e102b!2sTuru%201%2C%20Kanepi%2C%2063101%20P%C3%B5lva%20maakond!5e0!3m2!1sen!2see!4v1620124220948!5m2!1sen!2see"
                                    width="600" height="450" style={{border:0}} loading="lazy"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectio section-lg bg-default">
                <div className="container text-center">
                    <h3 className="with-divider-blue">Personal</h3>
                    <div className="row row-20 justify-content-center">
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".1s"><img src={DentistImg}/>
                            <h5 className="pt-2">Dr. Kairi Rebase</h5>
                            <span>Hambaarst</span>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".2s"><img src={DentistImg}/>
                            <h5 className="pt-2">Tiina Paumets</h5>
                            <span>Hambaraviõde</span>
                        </div>
                    </div>
                </div>
            </section>
        </>;
    }
}