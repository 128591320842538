import * as React from "react";



export default class Team extends React.Component {
    render() {
        return <>
            <section className="breadcrumbs-custom bg-image">
                <div className="container">
                    <h2 className="breadcrumbs-custom-title with-divider-blue">Personal</h2>
                </div>
            </section>
            <section className="section section-lg bg-default">
                <div className="container">
                    <div className="row row-30 justify-content-center">
                        <div className="col-lg-3 col-sm-6 col-6 wow fadeInUp">
                            <div className="team-profile"><a className="team-profile__media"
                                                             href="#"><img src="https://via.placeholder.com/370"/></a>
                                <div className="team-profile__caption">
                                    <h4><a href="#">Dr. Tahvel Kahvel</a></h4>
                                    <p>Tartu kliinik</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".1s">
                            <div className="team-profile"><a className="team-profile__media"
                                                             href="#"><img src="https://via.placeholder.com/370"/></a>
                                <div className="team-profile__caption">
                                    <h4><a href="#">Dr. Tahvel Kahvel</a></h4>
                                    <p>Mustla kliinik</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".2s">
                            <div className="team-profile"><a className="team-profile__media"
                                                             href="#"><img src="https://via.placeholder.com/370"/></a>
                                <div className="team-profile__caption">
                                    <h4><a href="#">Dr. Tahvel Kahvel</a></h4>
                                    <p>Tartu kliinik</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".3s">
                            <div className="team-profile"><a className="team-profile__media"
                                                             href="#"><img src="https://via.placeholder.com/370"/></a>
                                <div className="team-profile__caption">
                                    <h4><a href="#">Dr. Tahvel Kahvel</a></h4>
                                    <p>Kanepi kliinik</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".4s">
                            <div className="team-profile"><a className="team-profile__media"
                                                             href="#"><img src="https://via.placeholder.com/370"/></a>
                                <div className="team-profile__caption">
                                    <h4><a href="#">Dr. Tahvel Kahvel</a></h4>
                                    <p>Tartu kliinik</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".5s">
                            <div className="team-profile"><a className="team-profile__media"
                                                             href="#"><img src="https://via.placeholder.com/370"/></a>
                                <div className="team-profile__caption">
                                    <h4><a href="#">Dr. Tahvel Kahvel</a></h4>
                                    <p>Tartu kliinik</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".5s">
                            <div className="team-profile"><a className="team-profile__media"
                                                             href="#"><img src="https://via.placeholder.com/370"/></a>
                                <div className="team-profile__caption">
                                    <h4><a href="#">Dr. Tahvel Kahvel</a></h4>
                                    <p>Tartu kliinik</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay=".5s">
                            <div className="team-profile"><a className="team-profile__media"
                                                             href="#"><img src="https://via.placeholder.com/370"/></a>
                                <div className="team-profile__caption">
                                    <h4><a href="#">Dr. Tahvel Kahvel</a></h4>
                                    <p>Tartu kliinik</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>;
    }
}