import * as React from "react";



export default class Error404 extends React.Component {
    render() {
        return <>
            <section className="breadcrumbs-custom bg-image">
                <div className="container">
                    <h2 className="breadcrumbs-custom-title with-divider-blue">Error 404.</h2>
                    <p>Oih! Tundub, et midagi läks valesti. Mine <a href="/">avalehele</a> tagasi ning proovi uuesti. </p>
                </div>
            </section>
        </>;
    }
}