import * as React from "react";
import NavbarBrand from '../assets/images/blwendent.png';



export default class Navmenu extends React.Component {
    render() {

        return <header className="section page-header">
            <div className="rd-navbar-wrap">
                <nav className="rd-navbar rd-navbar-classic" data-layout="rd-navbar-fixed"
                     data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed"
                     data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-fixed"
                     data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-fixed"
                     data-xl-device-layout="rd-navbar-fixed"
                     data-xl-stick-up-offset="46px" data-xxl-stick-up-offset="46px" data-lg-stick-up="true"
                     data-xl-stick-up="true" data-xxl-stick-up="true">
                    <div className="rd-navbar-collapse-toggle rd-navbar-fixed-element-1"
                         data-rd-navbar-toggle=".rd-navbar-collapse"><span/></div>
                    <div className="rd-navbar-inner-wrap">
                        <div className="rd-navbar-aside-outer rd-navbar-collapse">
                            <div className="rd-navbar-collapse__phone"><span>Broneeri aeg:</span><i
                                className="fas fa-phone pr-1"/><a href="tel:3727477000" className="pr-1">Tartu</a> | <a href="tel:3727477000" className="pr-1 pl-1">Mustla</a> | <a href="tel:7977531" className="pl-1">Kanepi</a></div>
                        </div>
                        <div className="rd-navbar-main-outer">
                            <div className="rd-navbar-main">
                                <div className="rd-navbar-panel">
                                    <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap">
                                        <span/></button>
                                    <div className="nav-logo"><a className="brand" href="/"><img src={NavbarBrand}/></a>
                                    </div>
                                </div>
                                <div className="rd-navbar-main-element">
                                    <div className="rd-navbar-nav-wrap">
                                        <ul className="rd-navbar-nav">
                                            <li className="rd-nav-item active"><a className="rd-nav-link"
                                                                                  href="/">Avaleht</a>
                                            </li>
                                            <li className="rd-nav-item"><a className="rd-nav-link">Kliinikud</a>
                                                <ul className="rd-menu rd-navbar-dropdown">
                                                    <li className="rd-dropdown-item pb-2"><a
                                                        className="rd-dropdown-link"
                                                        href="/tartu">Tartu</a>
                                                    </li>
                                                    <li className="rd-dropdown-item pb-2"><a
                                                        className="rd-dropdown-link"
                                                        href="/mustla">Mustla</a>
                                                    </li>
                                                    <li className="rd-dropdown-item pb-2"><a
                                                        className="rd-dropdown-link"
                                                        href="/kanepi">Kanepi</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="rd-nav-item pb-2"><a className="rd-nav-link"
                                                                                href="/kontakt">Kontakt</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>;
    }
}