import React from 'react';
import Navmenu from "./components/navmenu";
import Home from "./components/home";
import Footer from "./components/footer";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Tartu from "./components/tartu";
import Mustla from "./components/mustla";
import Kanepi from "./components/kanepi";
import ServiceFirst from "./components/Service-first";
import Pricing from "./components/pricing";
import Team from "./components/team";
import Error404 from "./components/404";
import Contact from "./components/contact";

class App extends React.Component {
    render() {
        return <BrowserRouter>
            <div className="preloader">
                <div className="preloader-body">
                    <div className="cssload-container">
                        <div className="cssload-speeding-wheel"/>
                    </div>
                    <p>Laadin...</p>
                </div>
            </div>
            <div className="page">
                <Navmenu/>
                <Switch>
                    <Route component={Home} path="/" exact={true}/>
                    <Route component={Tartu} path="/tartu"/>
                    <Route component={Mustla} path="/mustla"/>
                    <Route component={Kanepi} path="/kanepi"/>
                    <Route component={ServiceFirst} path="/service-first"/>
                    <Route component={Pricing} path="/hinnakiri"/>
                    <Route component={Team} path="/personal"/>
                    <Route component={Contact} path="/kontakt"/>
                    <Route component={Error404} />
                </Switch>
                <Footer/>
            </div>
        </BrowserRouter>
    }
}

export default App;
