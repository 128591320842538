import * as React from "react";
import FooterBrand from '../assets/images/blwendent.png';



export default class Footer extends React.Component {
    render() {

        return <footer className="section footer-classic">
            <div className="container">
                <div className="row row-30 justify-content-center">
                    <div className="col-md-6 col-lg-3 col-12 text-center text-sm-left wow fadeInLeft text-center my-5 my-lg-0">
                        <a className="brand" href="/"><img className="brand-logo-dark"
                                                                    src={FooterBrand} alt="" width="78"
                                                                    height="16"/><img
                            className="brand-logo-light" src={FooterBrand} alt="" width="78" height="16"/></a>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 col-6 d-flex flex-column wow fadeInUp text-center"
                         data-wow-delay=".3s">
                        <div className="heading-6">Kontakt</div>
                        <div className="work-schedule pb-3"><span
                            className="pb-1">E - R: 9:00 kuni 17:00</span><span><br/>L - P: Suletud</span>
                        </div>
                        <a className="pb-1" href="tel:+372 7477000"><i className="fas fa-phone pr-2"/>+372 7477000</a>
                        <div className=""><a><i className="fa fa-map-marker pr-2"/>Ropka tee 6, Tartu</a></div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 col-6 wow fadeInUp text-center" data-wow-delay=".1s">
                        <div className="heading-6">Kliinikud</div>
                        <ul className="nav-list">
                            <li><a href="/tartu">Tartu</a></li>
                            <li><a href="/mustla">Mustla</a></li>
                            <li><a href="/kanepi">Kanepi</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-aside">
                <div className="container">
                    <div
                        className="row row-10 flex-column-reverse flex-lg-row justify-content-center align-items-center">
                        <div className="col-lg-7 text-lg-left text-center wow fadeInLeft">
                            <p className="rights"><span>&copy;&nbsp; </span><span
                                className="copyright-year"/><span>&nbsp;</span><span>Blendent</span><span>.&nbsp;</span><span>Kõik õigused reserveeritud.</span><span>&nbsp;</span></p>
                        </div>
                        <div className="col-lg-5 text-lg-right text-center wow fadeInRight">
                            <ul className="social-list">
                                <li><a className="fa fa-facebook-square" href="https://www.facebook.com/Blendenthambaravi"/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>;
    }
}