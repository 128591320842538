import * as React from "react";



export default class Pricing extends React.Component {
    render() {
        return <>
            <section className="breadcrumbs-custom bg-image">
                <div className="container">
                    <h2 className="breadcrumbs-custom-title with-divider-blue wow fadeInLeft">Hinnakiri</h2>
                    <p className="pt-2 wow fadeInUp">Tutvu lähemalt meie hinnakirjaga. <br/>Käesolev hinnakiri on kehtiv seisuga 01.05.2021.</p>
                </div>
                <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 col-10 pt-3 text-center">
                    <ul className="price-list">
                        <li className="wow fadeInUp" data-wow-delay=".1s"><span
                            className="service-type">Teenus</span><span
                            className="service-price">10€</span></li>
                        <li className="wow fadeInUp" data-wow-delay=".2s"><span
                            className="service-type">Teenus</span><span
                            className="service-price">50€</span></li>
                        <li className="wow fadeInUp" data-wow-delay=".3s"><span
                            className="service-type">Teenus</span><span
                            className="service-price">90€</span></li>
                        <li className="wow fadeInUp" data-wow-delay=".4s"><span
                            className="service-type">Teenus</span><span
                            className="service-price">200€+</span></li>
                    </ul>
                </div>
                </div>
            </section>

        </>;
    }
}