import * as React from "react";
import HeaderImg1 from '../assets/images/intro-img-1-189x327.png';
import HeaderImg2 from '../assets/images/intro-img-2-185x144.png';
import HeaderImg3 from '../assets/images/intro-img-3-152x261.png';
import HeaderImg4 from '../assets/images/bg.jpg';
import ServiceImg1 from '../assets/images/service-1-370x232.jpg';
import ServiceImg2 from '../assets/images/service-2-370x232.jpg';
import ServiceImg3 from '../assets/images/service-3-370x232.jpg';
import ServiceIcon1 from '../assets/images/icon-1-60x60.png';
import ServiceIcon2 from '../assets/images/icon-2-60x60.png';
import ServiceIcon3 from '../assets/images/icon-3-60x60.png';
import ServiceIcon4 from '../assets/images/icon-4-60x60.png';
import NavbarBrand from '../assets/images/logo1.png';
import HomeBanner from '../assets/images/blwendent.png';




export default class Home extends React.Component {
    render() {

        return <>

            <section className="section section-lg section-xl-custom bg-creative section-home">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-xl-6 text-center text-sm-left">
                            <h1>Hambaravi kliinik Tartus, Mustlas ning Kanepis</h1>
                            <h2 className="wow fadeInLeft pt-3" data-wow-delay=".2s">Helista ja Broneeri Aeg!</h2>
                            <p className="wow fadeInLeft big" data-wow-delay=".3s">Võta meiega ühendust helistades numbrile:</p>
                            <div className="pt-3"><a className="wow fadeInRight text-xxl font-weight-bold" href="tel:+372 7477000"><i
                                className="fas fa-phone pr-2"/>+372 7477000</a></div>
                            <div className="button-group group-sm justify-content-center justify-content-sm-start d-flex flex-wrap">
                                <a className="wow fadeInLeft button button-primary button-shadow button-md" href="/kontakt"
                                   data-wow-delay=".5s">Broneeri aeg</a></div>
                        </div>
                    </div>
                </div>
            </section>

        </>;
    }
}